<template>
  <div style="background-color: #f5faff; z-index: 0; height: 100vh">
    <NavBar
    style="max-height=50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>
    <div>
     <v-row justify="center">
      <v-dialog v-model="appointmentTimeOverModal" persistent max-width="290">
       <v-card>
        <v-card-title style="word-break:normal">
         Teleconsultation time is up !
       </v-card-title>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="green darken-1" text @click="closeDialogAppointmentTimeOverModal">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</div>

<div>
 <v-row justify="center">
  <v-dialog v-model="appointmentStartMessageModal" persistent max-width="290">
   <v-card>
    <v-card-title style="word-break:normal">
     Please join 5 minutes before appointment time!
   </v-card-title>
   <v-card-actions>
     <v-spacer></v-spacer>
     <v-btn color="green darken-1" text @click="closeDialogAppointmentStartMessageModal">
      OK
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</v-row>
</div>

<div>
 <v-row justify="center">
  <v-dialog v-model="exitModal" persistent max-width="290">
   <v-card>
    <v-card-title style="word-break:normal">
     Prescription has been saved successfully.<br>Do you want to Exit?
   </v-card-title>
   <v-card-actions>
     <v-spacer></v-spacer>
     <v-btn color="green darken-1" text @click="closeExitModal">
      OK
    </v-btn>
      <v-btn color="green darken-1" text @click="redirectToViewAppointment">
      Cancel
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</v-row>
</div>
  <div>
    <v-row>
      <v-dialog v-model="prescriptionDialog" persistent max-width = "50%">
        <v-card style="padding: 40px 30px">
          <v-row>
            <v-col cols="12">
              <div style="display: flex; flex-direction: row; justify-content: space-between;  margin-top: 10px">
                <div style="display: flex; flex-direction: row;">
                 <div> <p>Booking ID:</p></div>
                 <div><span style=" font-weight: 600">{{
                    view_prescription_object.appointment_reference_number
                  }}</span></div>
                </div>
                <div>
                   <v-btn
                    icon
                    color="red"
                    class="text--white"
                    v-on:click="closePrescriptionDialogue"
                  >
                    <h2>X</h2>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style = "margin: 10px 0px;">
            <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left">
              Presenting Symptoms
            </v-col>
            <v-col cols="10" lg="10" sm="10" md="10" xl="10">
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.presenting_symptoms}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
              Past medical history
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.medicalHistory}}</p>
            </v-col>
            <v-col style="border-style: hidden groove hidden hidden" cols="4" lg="4" sm="4" md="4" xl="4">
              Past surgical history
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.surgicalHistory}}</p>
            </v-col>
            <v-col cols="4" lg="4" sm="4" md="4" xl="4">
              Family history
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="family_history" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.familyHistory}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
              Regular taking/ Current medicine
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.current_taking_medicines}}</p>
            </v-col>
            <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
              Known Drug Allergies
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="allergies" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.allergies}}</p>
            </v-col>
            <v-col cols="4" lg="4" sm="4" md="4" xl="4">
              Vaccination History
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.vaccinationHistory}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
              Diagnosis
            </v-col>
            <v-col cols="10" lg="10" sm="10" md="10" xl="10">
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.diagnosed_with}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col cols="3" sm="3" md="3" align="left">
              Treatment
            </v-col>
            <v-col cols="12" sm="12" md="12" align="left">
              <table style=" width: 100%; background-color: #F1F6FF;">
                <tr align="left" style="padding: 15px;">
                  <th style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</th>
                  <th style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</th>
                  <th style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</th>
                  <th style="font-size:16px; line-height: 20px; padding: 5px;">Duration</th>
                </tr>
                <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in view_prescription_object.prescription_object" :key=index>
                   <td style="padding:5px;font-weight: 500">{{item.medicine}}</td>
                   <td style="padding:5px;font-weight: 500">{{item.dosage}}</td>
                   <td style="padding:5px;font-weight: 500">{{item.frequency}}</td>
                   <td style="padding:5px;font-weight: 500">{{item.duration}}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
              Investigations needed
            </v-col>
            <v-col cols="10" lg="10" sm="10" md="10" xl="10">
              <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
              <p class="variable">{{view_prescription_object.investigationNeeded}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
              To Come after
            </v-col>
            <v-col cols="3" lg="3" sm="3" md="3" xl="3">
              <!-- <v-select disabled background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
              <p class="variable">{{view_prescription_object.comeAfter}}</p>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row  style = "margin: 10px 0px;">
            <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
              Refer To
            </v-col>
            <v-col cols="3" lg="3" sm="3" md="3" xl="3">
              <!-- <v-select disabled background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/> -->
              <p class="variable">{{view_prescription_object.referredSpecialistName}}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </div>


<v-app style="background-color: #f5faff" align="center">
  <v-row>
    <div>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col  cols="11"
      sm="11" style="margin-top: 15%;">
      <div class="timerClass" style="margin-left: 9%;">
         <p style="border:2px; background-color: red; color: white; padding: 5px;">{{countDownTimer}}</p>
      </div>
      <!-- <iframe
      :src="appointment_url"
      allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:125%"
      ></iframe> -->
      <whereby-embed :room="appointment_url"  audio=on video=on />
    </v-col>
  </v-row>
</div>
<v-progress-circular
indeterminate
color="primary"
v-if="isLoading"
style="position: fixed; left: 400px;top: 80px;left: 800px;overflow: scroll;"
></v-progress-circular>
<div style="position: fixed; left: 400px;top: 60px; width: 70vw;overflow: scroll; height: 80vh;"  align="center" v-if="!isLoading">
  <h3
  style="
  text-align: left;
  margin-top: 30px;
  font-weight: 800;
  letter-spacing: 0.08em;
  margin-bottom: 10px;
  margin-left: 10px;
  "
  >
  Patient Details
</h3>

<div>
  <!-- <v-card width="98%" style="margin-right: 5%; border-radius: 12px">
    <div style="display: flex; flex-direction: column">
      <div
      style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 15px !important;
      "
      >
      <div>
        <img
        src="https://img.icons8.com/color/50/000000/user.png"
        />
      </div>
      <div
      style="display: flex; flex-direction: column; padding: 2px"
      >
      <div align="left">
        <h6
        style="margin-top: 5px; color: #828282 !important"
        align="left"
        >
        Patient Name
      </h6>
    </div>
    <div>
      <p style="margin-top: 2px; font-weight: 700">
        {{ patientDetails.customer_name }}
      </p>
    </div>
  </div>
</div>
<div
style="
display: flex;
flex-direction: row;
justify-content: space-evenly;
margin-top: 30px !important;
"
>
<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">Age:</p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{ age }}</strong>
  </div>
</div>

<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/gender.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">Gender:</p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{ patientDetails.customer_gender }}</strong>
  </div>
</div>

<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/height.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">Height:</p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{ patientDetails.customer_height }}</strong>
  </div>
</div>

<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/weight.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">Weight:</p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{ patientDetails.customer_weight }}</strong>
  </div>
</div>

<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">
      Alcoholic:
    </p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{
      patientDetails.alcoholic ? "Yes" : "No"
    }}</strong>
  </div>
</div>

<div style="display: flex; flex-direction: row">
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
    />
  </div>
  <div>
    <p style="margin-top: 3px; margin-left: 5px">Smoker:</p>
  </div>
  <div style="margin-top: 3px; margin-left: 5px">
    <strong>{{
      patientDetails.smoker ? "Yes" : "No"
    }}</strong>
  </div>
</div>
</div>
</div> -->
<!-- </v-card> -->
            <!-- <v-card style="width: 80vw;">
              <div style="display: flex; flex-direction: column">
                <div align="left" style="padding: 24px 32px 0 32px">
                  <p style="font-weight: bold; margin-bottom: 3px">
                    BOOKING ID: {{ appointment_reference_number }}
                  </p>
                </div>
                <v-divider />
                <div style="padding: 24px 32px 0 32px;">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    ">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      <div>
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </div>
                      <div style="display: flex; flex-direction: column; margin-left: 10px;" align="left">
                        <div style="color: #828282">Patient Name</div>
                        <div style="font-weight: bold" align="left" v-if="patientDetails.customer_name">
                          {{ patientDetails.customer_name}}
                        </div>
                         <div style="font-weight: bold" align="left" v-else>
                          {{ patientDetails.dependent_name}}
                        </div>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-right: 50px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div align="center">
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">Date</div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                              <DateRendered :dateVal="dateOfAppointment"> </DateRendered>

                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-left: 20px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/clock.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">
                            Time Slot
                          </div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                            <TimeRender :timeSlot="timeSlot"> </TimeRender>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-left: 32px;
                  padding-top: 10px;
                "
                align="left">
                <p style="color: #828282; font-weight: 600;">Description</p>
                <div style="font-weight: normal" align="left">
                  <p>
                    {{ description }}
                  </p>
                </div>
                <br>
                <br>
                <p style="color: #828282;  font-weight: 600;">Patient Details</p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-right: 5%;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Age:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ age }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Gender:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_gender " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_gender }}</span>
                      <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_gender }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/height.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Height:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_height" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_height }}</span>

                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_height }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Weight:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_weight " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_weight }}</span>
                       <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_weight }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">
                        Alcoholic:
                      </p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.alcoholic" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.alcoholic
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_alcoholic
                      }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Smoker:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.smoker" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.smoker
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_smoker
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-card> -->
              <v-card style="width: 70vw;">
                <div style="display: flex; flex-direction: column">
                  <div align="left" style="padding: 24px 32px 0 32px">
                    <p style="font-weight: bold; margin-bottom: 3px">
                      BOOKING ID: {{ appointment_reference_number }}
                    </p>
                  </div>
                  <v-divider />
                  <div style="padding: 24px 32px 0 32px;">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;">
                      <div
                        style="
                          display: flex;
                          flex-direction: row;">
                        <div>
                          <img
                            src="https://img.icons8.com/color/50/000000/user.png"
                          />
                        </div>
                        <div style="display: flex; flex-direction: column; margin-left: 10px;" align="left">
                          <div style="color: #828282">Patient Name</div>
                          <div style="font-weight: bold" align="left">
                            {{ nameOfPatient }}
                          </div>
                        </div>
                      </div>

                      <div style="display: flex; flex-direction: row">
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            margin-right: 50px;
                          "
                        >
                          <div style="display: flex; flex-direction: row">
                            <div align="center">
                              <img
                                style="width: 25px; height: 25px"
                                src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                              />
                            </div>
                            <div style="margin: 1px; color: #828282">Date</div>
                          </div>
                          <div style="display: flex; flex-direction: row">
                            <div style="font-weight: normal">
                                <DateRendered :dateVal="dateOfAppointment"> </DateRendered>

                            </div>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                          "
                        >
                          <div style="display: flex; flex-direction: row">
                            <div>
                              <img
                                style="width: 25px; height: 25px"
                                src="https://s3iconimages.mymedicine.com.mm/clock.svg"
                              />
                            </div>
                            <div style="margin: 1px; color: #828282">
                              Time Slot
                            </div>
                          </div>
                          <div style="display: flex; flex-direction: row">
                            <div style="font-weight: normal">
                              <TimeRender :timeSlot="timeSlot"> </TimeRender>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    padding-left: 32px;
                    padding-top: 10px;
                  "
                  align="left"
                >
                  <p style="color: #828282;  font-weight: 600;">Patient Details</p>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding-right: 5%;
                    "
                  >
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Age:</p>
                      </div>
                      <div style="margin-left: 5px">
                        <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ age }}</span>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Gender:</p>
                      </div>
                      <div style="margin-left: 5px">
                        <span v-if="patientDetails.customer_gender " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_gender }}</span>
                        <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_gender }}</span>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/height.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Height:</p>
                      </div>
                      <div style="margin-left: 5px">
                        <span v-if="patientDetails.customer_height" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_height }}</span>

                        <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_height }}</span>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Weight:</p>
                      </div>
                      <div style="margin-left: 5px">
                        <span v-if="patientDetails.customer_weight " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_weight }}</span>
                         <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_weight }}</span>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">
                          Alcoholic:
                        </p>
                      </div>
                      <div style="margin-left: 5px">
                        <span v-if="patientDetails.alcoholic" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                          patientDetails.alcoholic
                        }}</span>
                        <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                          patientDetails.dependent_alcoholic
                        }}</span>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row;">
                      <div>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
                        />
                      </div>
                      <div>
                        <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Smoker:</p>
                      </div>
                      <div style="margin-left: 5px">
                        <span v-if="patientDetails.smoker" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                          patientDetails.smoker
                        }}</span>
                        <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                          patientDetails.dependent_smoker
                        }}</span>
                      </div>
                    </div>

                  </div>
                     <v-divider style="width:95%" />
                  <p style="color: #828282; font-weight: 600; padding-top:30px;">Description</p>
                  <div style="font-weight: normal" align="left">
                    <p>
                      {{ description }}
                    </p>
                  </div>
                  <br>
                  <div v-if="allAttachments.length > 0">
                    <p style="color: #828282; font-weight: 600;">View Attachment</p>
                    <div style="display: flex; flex-direction: row wrap;">
                      <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                        <v-chip :href="attachments" target="_blank">
                          {{attachments.split("/")[3]}}
                        </v-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
</div>
<v-tabs
v-model="tab"
background-color="primary"

style="margin-top: 2%; margin-bottom: 1%">
<v-tab v-for="item in items" :key="item.tab">
  {{ item.tab }}
</v-tab>
</v-tabs>
<v-tabs-items v-model="tab">
  <v-tab-item>
    <v-card style="padding: 32px 24px;">
      <!-- <v-row>
        <v-col cols="12">
          <div style="display: flex; flex-direction: row; margin-top: 10px;">
            <div>
             Booking ID:
           </div>
           <div align="left" style="width: 50%;">
             <span style="padding-left: 1%; font-weight: 600;">{{appointment_reference_number}}</span>
           </div>
         </div>
       </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div id="form" style="width:95%;">
            <fieldset class="fieldset_class" style="padding:15px">
              <legend align="left" style="font-weight:bold;">Covid Related Symptoms</legend>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                  v-model="selected"
                  label="Fever"
                  value="Fever"
                  ></v-checkbox>
                  <v-checkbox
                  v-model="selected"
                  label="Shortness of breath"
                  value="Shortness of breath"
                  ></v-checkbox>
                  <v-checkbox
                  v-model="selected"
                  label="Flu like illness(Sneezing, cough)"
                  value="Flu like illness(Sneezing, cough)"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                  v-model="selected"
                  label="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                  value="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                  ></v-checkbox>
                  <v-checkbox
                  v-model="selected"
                  label="Contact with a person who had travelled to COVID-19 suspected areas"
                  value="Contact with a person who had travelled to COVID-19 suspected areas"
                  ></v-checkbox>
                  <v-checkbox
                  v-model="selected"
                  label="None of the Above"
                  value="None of the Above"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                  v-model="selected"
                  label="Loss of smell"
                  value="No Smell"
                  ></v-checkbox>
                  <v-checkbox
                  v-model="selected"
                  label="Travelling history within 2 weeks"
                  value="Travelling history within 2 weeks"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </fieldset>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <v-row style="width: 95%; margin-top: 10px; margin-left: 10px; padding:15px;">
        <v-col cols="10" sm="5" md="4" align="left">
          Allergies
          <v-textarea class="textarea_class" v-model="allergies" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="10" sm="5" md="4" align="left">
          Diagnosed With
          <v-textarea class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="10" sm="5" md="4" align="left">
          Current Taking Medicines
          <v-textarea class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-divider />
      <v-row  style="width: 95%; margin-top: 10px;margin-left: 10px;">
        <v-col cols="6" sm="6" md="6" align="left">
          Prescription
        </v-col>
        <v-col cols="12" sm="12" md="12" align="left">
          <table style=" width: 100%; background-color: #F1F6FF;">
            <tr align="left" style="padding: 15px;">
              <td style="font-size:16px; line-height: 20px;">Medicine</td>
              <td style="font-size:16px; line-height: 20px;">Unit(Tablet/Syrup)</td>
              <td style="font-size:16px; line-height: 20px;">Dosage</td>
            </tr>
            <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
              <td><v-text-field class="textfield_class" solo outlined
               v-model="item.medicine"/></td>
              <td><v-text-field class="textfield_class" solo outlined
                 v-model="item.unit"/></td>
              <td><v-text-field class="textfield_class" solo outlined
                   v-model="item.dosage"/></td>
              <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
              <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.unit,item.dosage)"></td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-divider />
      <v-row style="width: 95%; margin-top: 10px;margin-left: 10px">
        <v-col cols="10" sm="5" md="6" align="left">
          Suggestions (If Any)
          <v-textarea class="textarea_class" v-model="suggestions" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="10" sm="5" md="6" align="left">
          Examinations to be done (If Any)
          <v-textarea class="textarea_class" v-model="exams" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-divider />
      <v-row  style="width: 95%; margin-top: 10px;">
        <v-col cols="3" sm="3" md="3">
          <v-checkbox
          v-model="need_follow_up"
          label="Need Follow up appointment"
          value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-checkbox
          v-model="referred_to_specialist"
          label="Referred to a specialist"
          value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-checkbox
          v-model="patient_no_show"
          label="Patient No Show"
          value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-checkbox
          v-model="unstable_connectivity"
          label="Unstable Connectivity"
          value="true"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color = primary @click="save_prescription"> Save Prescription & Exit </v-btn>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left">
          Presenting Symptoms
        </v-col>
        <v-col cols="10" lg="10" sm="10" md="10" xl="10">
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Past medical history
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Past surgical history
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Family history
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="family_history" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Regular taking/ Current medicine
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Known Drug Allergies
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="allergies" solo rows=1></v-textarea>
        </v-col>
        <v-col cols="4" lg="4" sm="4" md="4" xl="4">
          Vaccination History
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
          Diagnosis
        </v-col>
        <v-col cols="10" lg="10" sm="10" md="10" xl="10">
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" align="left">
          Treatment
        </v-col>
        <v-col cols="12" sm="12" md="12" align="left">
          <table style=" width: 100%; background-color: #F1F6FF;">
            <tr align="left" style="padding: 15px;">
              <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
              <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
              <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
              <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
            </tr>
            <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
              <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
               v-model="item.medicine"/></td>
              <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
               v-model="item.dosage"/></td>
              <td style="padding:5px;"><v-select outlined background-color="white" solo :items="frequency_list" v-model="item.frequency"></v-select></td>
               <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
               v-model="item.duration"/></td>
              <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"></td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left">
          Investigations needed
        </v-col>
        <v-col cols="12" lg="12" sm="12" md="12" xl="12">
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
          To Come after
        </v-col>
        <v-col cols="3" lg="3" sm="3" md="3" xl="3">
          <v-select background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
          Refer To
        </v-col>
        <v-col cols="3" lg="3" sm="3" md="3" xl="3">
          <v-select background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/>
        </v-col>
        <v-col cols="3" lg="3" sm="3" md="3" xl="3" v-if="selected_specialization.specialization_name == 'Others'">
          <v-textarea background-color="#F1F6FF" class="textarea_class" v-model="others" solo rows=1></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color = primary @click="save_prescription"> Save Prescription & Exit </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-tab-item>
  <v-tab-item>
      <div>
        <div
        style="
        margin-right: 2%;
        border-radius: 12px;
        "
        >
        <div
        style="display: flex; flex-dirextion: row; flex-wrap: wrap"
        >
        <v-card
        width="20vw"
        style="border-radius: 12px; margin: 2%"
        v-for="prescription in prescriptionDetails"
        :key="prescription.appointment_reference_number"
        >
        <div
        style="
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 15px !important;
        "
        >
        <div>
          <h4
          align="left"
          style="padding-top: 10px; margin-left: 20px"
          v-if="prescription.diagnosed_with != ''"
          >
          {{ prescription.diagnosed_with }}
          </h4>

          <h4
          align="left"
          style="padding-top: 10px; margin-left: 20px"
          v-else
          >
          NA
          </h4>
        </div>

    <div
    style="
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    "
    >
    <div
    style="
    display: flex;
    flex-direction: column;
    padding: 2px;
    margin-top: 5%;
    margin-left: 5%;
    "
    >
    <div>
      <h6
      style="
      margin-top: 20%;
      color: #828282 !important;
      "
      align="left"
      >
      Doctor Name
    </h6>
  </div>
  <div>
    <p
    style="margin-top: 2px; font-weight: 700; font-size: 0.8em"
    align="left"
    >
    {{ prescription.doctor_name }}
  </p>
</div>
</div>

<div>
  <div
  style="
  display: flex;
  flex-direction: row;
  margin-top: 35%;
  margin-right: 10px;
  "
  >
  <div>
    <img
    style="width: 25px; height: 25px"
    src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
    />
  </div>
  <div style="margin-top: 3px; margin-left: 5px;font-size: 0.8em">
    <strong>{{
      prescription.date_of_appointment
    }}</strong>
  </div>
</div>
</div>
</div>

<div style="margin-top: 10px; margin-bottom: 10px">
  <v-btn
  color="primary"
  width="85%"
  @click="viewPrescription(prescription._id)"
  style="border-radius: 8px; bottom: 10px"
  >
  View Prescription
</v-btn>
</div>
</div>
</v-card>
</div>
</div>
</div>
</v-tab-item>
</v-tabs-items>
</div>
</v-row>
</v-app>
</div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import DateRendered from "../Customer/dateRender.vue";
import TimeRender from "../Customer/TimeRender.vue";
export default {
  name: "DoctorVideoCall",
  components: {
    NavBar,
    DateRendered,
    TimeRender
  },
  data() {
    return {
      countDownTimer : "",
      currentUser: "",
      currentUserName: "",
      pageName: "DoctorVideoCall",
      booking_id: "",
      doctor_id: "",
      patientDetails: "",
      appointment_url: "",
      age: "",
      appointmentDetails: "",
      allAttachments: "",
      specialization: "",
      patient_id: "",
      nameOfPatient: "",
      tab: null,
      prescriptionDetails: "",
      items: [
      { tab: "Write Prescription", content: "Tab 1 Content" },
      { tab: "Prescription History", content: "Tab 2 Content" },
      ],
      appointment_reference_number: "",
      age: "",
      prescriptionId: "",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      need_follow_up: false,
      exitModal:false,
      exams: "",
      suggestions: "",
      current_taking_medicines: "",
      diagnosed_with: "",
      allergies: "",
      selected: [],
      date_of_appointment:"",
      isLoading : false,
      appointmentTimeOverModal: false,
      showTimer : true,
      appointmentStartMessageModal: false,
      timeSlot:"",
      dateOfAppointment:"",
      description:"",
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
      follow_up_list: ["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
      come_after: "",
      others: "",
      currentEpochTime: "",
      specialization_list: [],
      selected_specialization: "",
      followUpDate: "",
      prescriptionDialog: false,
      view_prescription_object: "",
      view_selected_prescription: ""
    };
  },

  mounted() {
    this.isLoading = true
    this.currentUser = this.$cookies.get("doctorToken");
    var presc_dets = "";
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var params = this.$route.params;
      this.booking_id = params["booking_id"];
      console.log(this.booking_id);
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        bookingId: this.booking_id,
        page: 1,
        limit: 8
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/doctorVideoCallPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.doctor_id = successResponse.data.doctor_data._id;
        this.appointmentObject = successResponse.data.appointment_data;
        this.appointment_reference_number = successResponse.data.appointment_data.appointment_reference_number;
        this.appointmentDetails = successResponse.data.appointment_data;
        this.patient_id = this.appointmentDetails.booked_for_id;
        this.nameOfPatient = this.appointmentDetails.booked_for_name;
        this.date_of_appointment=this.appointmentDetails.date_of_appointment;
        this.specialization = this.appointmentDetails.specialization_name;
        this.appointment_url = this.appointmentDetails.meeting_link;
        this.timeSlot = this.appointmentDetails.time_slot;
        this.description = this.appointmentDetails.description;
        this.allAttachments = this.appointmentDetails.file_attachment;
        this.patientDetails = successResponse.data.patient_data;
        if(successResponse.data.dependent_flag){
          this.age = this.getAge(this.patientDetails.dependent_dob);
        }
        else{
          this.age = this.getAge(this.patientDetails.customer_dob);
        }
        this.prescriptionDetails = successResponse.data.prescription_data.docs;
        var scriptEle = document.querySelector('whereby-embed');
        scriptEle.addEventListener("join", this.joinLogEvent);
        scriptEle.addEventListener("leave", this.leaveLogEvent);
        console.log("whereby error");
        this.isLoading = false;
        if(successResponse.data.appointment_prescription_data.length > 0)
        {
          presc_dets = successResponse.data.appointment_prescription_data[0];
          this.selected = presc_dets['covid_related_symptoms'];
          this.allergies = presc_dets['allergies'];
          this.diagnosed_with = presc_dets['diagnosed_with'];
          this.current_taking_medicines = presc_dets['current_taking_medicines'];
          this.suggestions = presc_dets['suggestion'];
          this.allergies = presc_dets['allergies'];
          if(presc_dets['need_follow_up'] == true)
            this.need_follow_up = "true"
          this.exams = presc_dets['examination_to_be_done'];
          if(presc_dets['patient_no_show'] == true)
            this.patient_no_show = presc_dets['patient_no_show'];
          if(presc_dets['referred_to_specialist'] == true)
            this.referred_to_specialist = "true";
          if(presc_dets['unstable_connectivity'] == true)
          {
            this.unstable_connectivity = "true";
          }
          if(presc_dets['prescription'].length > 0)
            this.prescription_object = presc_dets['prescription'];
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.come_after = presc_dets.comeAfter;
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
        .then((readAllSpecializationsResponse) => {
          var spec_list = readAllSpecializationsResponse.data.data;
          this.specialization_list.push({'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"});
          this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
          spec_list.forEach((x) =>{
            this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
          });
          let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
          if(obj)
          {
            this.selected_specialization = obj;
          }
          else if(successResponse.data.appointment_prescription_data.length > 0)
          {
            this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
            this.others = presc_dets.referredSpecialistName;
          }
        })
        .catch((readAllSpecializationsError) =>{
        });
        var user_config_body ={
          appointment_reference_number : this.appointment_reference_number,
          appointment_id : successResponse.data.appointment_data._id,
          meeting_link : successResponse.data.appointment_data.meeting_link,
          config_type : "VIDEO_CALL",
          user_type : "DOCTOR",
          user_id : this.doctor_id,
          user_name : this.currentUserName
        }
        this.timerOne();
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        if (errorResponse.response.status == 401) {
          window.alert("Sorry !! You are UNAUTHORIZED");
          this.$router.push({
            name: "DoctorLogin",
          });
        }
        else{
          window.alert("Something went wrong...");
        }
      });
    }
  },

methods: {
  closePrescriptionDialogue(){
    this.prescriptionDialog = false;
    this.view_prescription_object = "";
    this.view_selected_prescription = "";
  },
    leaveLogEvent(event){
      if(event.type === "leave")
      {
         var leaveLogEventBody={

              bookingId : this.booking_id,
               userType: "DOCTOR",
              token : this.currentUser,
              typeOfUser : "DOCTOR"
         }
              axios.post(process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",leaveLogEventBody)
          .then((exitCallLogEntryResponse)=>{

          })
          .catch((exitCallLogEntryError)=>{

        });

      }
   },
     joinLogEvent(event){
      if(event.type === "join")
      {
         var JoinLogEventBody={

              bookingId : this.booking_id,
               userType: "DOCTOR",
              token : this.currentUser,
              typeOfUser : "DOCTOR"
         }
              axios.post(process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",JoinLogEventBody)
          .then((joinCallEntryResponse)=>{

          })
          .catch((joinCallEntryError)=>{
        });
      }
   },
  closeExitModal(){
    this.exitModal = false;
     this.$router.push({
      name: "ViewAppointmentDoctor",
    });
  },
  redirectToViewAppointment(){
    this.exitModal = false;

  },
  sleep(booking_id,token,router){
   setTimeout(function(){
     var changeAppointmentStatusOnCompletiondata={
      bookingId : booking_id,
      token : token,
      typeOfUser : "DOCTOR"
    }
    axios.post(process.env.VUE_APP_BACKEND_URL + "/changeAppointmentStatusOnCompletion",changeAppointmentStatusOnCompletiondata)
    .then((changeAppointmentStatusOnCompletionSuccess)=>{
      this.$router.push({
        path:'/doctor/viewappointment/' + booking_id
      })
    })
    .catch((changeAppointmentStatusOnCompletionError)=>{
     router.push({
      path:'/doctor/viewappointment/' + booking_id
    })
   });
  },5000);
 },
 timerOne(){
  var startTime = new Date(this.appointmentObject.booking_epoch_time).getTime();
  var currentTime = new Date().getTime();
  if(startTime - currentTime > 600000){
    this.showTimer = true;
    // this.countDownTimer = "";
  }
  if(this.showTimer){
    var countDownDate = new Date(this.appointmentObject.booking_closing_epoch_time).getTime() ;
    var intervalOne = setInterval(() =>{
      var  today= new Date().getTime();
      var distance = countDownDate - today;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countDownTimer = minutes+":"+seconds;
      if(distance < 0){
        clearInterval(intervalOne);
        this.countDownTimer = "00:00";
        this.appointmentTimeOverModal = true;
        this.sleep(this.booking_id,this.currentUser,this.$router);
         //this.timerTwo();
      }
    },1000);
  }
  else{
    this.appointmentStartMessageModal = true;
  }
},
timerTwo(){
 this.executeSecondTimer = true;
 var endTime = this.appointmentObject.time_slot.split("-")[1]
 var bufferTime = Number(endTime.split(":")[1]) + 3;
 var combinedTime = endTime.split(":")[0] + ":" + bufferTime
 var countDownDate = new Date(this.appointmentObject.date_of_appointment + " " +String(combinedTime)+":00 GMT+0630").getTime() ;
 var  today= new Date().getTime();
 var distance = countDownDate - today;
 var intervalTwo = setInterval(() =>{
  distance = distance - 1000;
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  this.countDownTimer = minutes+":"+seconds;
  if(distance < 0){
   clearInterval(intervalTwo);
   this.countDownTimer = "00:00";
   this.appointmentTimeOverModal = true;
   this.executeSecondTimer = false;
 }
},1000);
},
save_prescription() {
  var DateInDateformat = new Date(this.date_of_appointment)
      if(this.come_after == "One Week"){
        DateInDateformat.setDate(DateInDateformat.getDate()+7)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
      else if(this.come_after == "Two Week"){
         DateInDateformat.setDate(DateInDateformat.getDate()+14)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
       else if(this.come_after == "One Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+1)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);

      }
       else if(this.come_after == "Two Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+2)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
       else if(this.come_after == "Three Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+3)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
  var presc_body = {
    covidRelatedSymptoms: this.selected,
    allergies: this.allergies,
    diagnosedWith: this.diagnosed_with,
    currentMedication: this.current_taking_medicines,
    suggestion: this.suggestions,
    examinationTBD: this.exams,
    followUp: this.need_follow_up,
    patientNoShow: this.patient_no_show,
    referredSpecialist: this.referred_to_specialist,
    unstableConnection: this.unstable_connectivity,
    appointmentReferenceNo: this.appointment_reference_number,
    doctorName: this.currentUserName,
    doctorId: this.doctor_id,
    customerName: this.nameOfPatient,
    customerId: this.patientDetails._id,
    appointmentDate: this.date_of_appointment,
    specialization: this.specialization,
    break_here_for_newtemplate_variable: "",
    presentSymptoms: this.presenting_symptoms,
    medicalHistory: this.medical_history,
    surgicalHistory: this.surgical_history,
    familyHistory: this.family_history,
    currentMedication: this.current_taking_medicines,
    allergies: this.allergies,
    vaccinationHistory: this.vaccination_history,
    diagnosedWith: this.diagnosed_with,
    investigationNeeded: this.investigations_needed,
    comeAfter: this.come_after ? this.come_after : "Not Required",
    followUpDate: this.followUpDate ? this.followUpDate : "",
    referredSpecialistName: this.selected_specialization.specialization_name != 'Others' ? this.selected_specialization.specialization_name : this.others,
    token : this.currentUser,
    typeOfUser : "DOCTOR"
  }
  var prescriptions = [];
  this.prescription_object.forEach(function(item, index, object) {
    if (item.medicine !== '') {
      prescriptions.push(item);
    }
  });
  if(prescriptions.length == 0)
    presc_body.prescription = [];
  else if(prescriptions.length > 0)
    presc_body.prescription = prescriptions;
  axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
  .then((createPrescriptionResponse) =>{
    this.exitModal = true
    //window.alert("Prescription Saved");
  })
  .catch((createPrescriptionError) =>{
    if(createPrescriptionError.response.status == 403)
      window.alert("Cannot update a blank prescription");
  });
},
add_rec() {
  this.prescription_object.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
},
remove_rec(medicine, unit, dosage) {
  this.prescription_object.forEach(function (item, index, object) {
    if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
    {
      object.splice(index, 1);
    }
  });
},
gotoLoginPage() {
  var deleteData = {
    idToken: this.currentUser,
  };
  axios
  .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
  .then((deleteResponse) => {
    this.$cookies.remove("doctorToken");
  })
  .catch((error) => {
  });
  this.$router.push({
    name: "DoctorLogin",
  });
},
getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
},
viewPrescription(prescriptionID) {
  this.prescriptionId = prescriptionID;
  // let routeData = this.$router.push({path: "/doctor/viewPrescription/"+ prescriptionID});
  var prescription_body = {
    "prescriptionId":prescriptionID,
    "token" : this.currentUser,
    "typeOfUser" : "DOCTOR"
  };
  axios.post(process.env.VUE_APP_BACKEND_URL+"/readParticularPrescription",prescription_body)
  .then((readParticularPrescriptionResponse) =>{
    this.view_prescription_object = readParticularPrescriptionResponse.data.data;
    let obj = this.specialization_list.find(o => o['specialization_name'] === this.view_prescription_object.referredSpecialistName);
    if(obj)
    {
      this.view_selected_prescription = obj;
    }
    else if(this.view_prescription_object)
    {
      this.view_selected_prescription = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
      this.view_prescription_object.others = this.view_prescription_object.referredSpecialistName;
    }
  })
  .catch((readParticularPrescriptionError) =>{
    console.log(readParticularPrescriptionError);
    window.alert("Something went wrong");
  });
  this.prescriptionDialog = true;
},
closeDialogAppointmentTimeOverModal(){
  this.$router.push({
    path:'/doctor/viewappointment/' + this.booking_id
  });
 },
 closeDialogAppointmentStartMessageModal(){
  this.$router.push({
    path:'/doctor/viewappointment/' + this.booking_id
  });
}
},
};
</script>

<style scoped>
whereby-embed{
  overflow:hidden;height:80vh;width:125%
}
.fieldset_class
{
  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-bottom-style: none;
}
.textfield_class {
  box-sizing: border-box;
  border-radius: 8px;
}
.timerClass{
   position: absolute;
   margin-left : 30px;
   margin-top: 10px;
   font-size:150%;
}
  ::-webkit-scrollbar {
    width: 10px !important;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

    /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey !important;
  }
</style>